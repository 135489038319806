<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div class="container">
    <div class="one mx-auto history-div">
      <div class="row">
        <div class="col-12">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="80"
            height="80"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#ffffff"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
      </div>
      <div class="one mx-auto mb-4" v-if="show_return_list">
        <span class="success-text" style="font-size: 1em"
          >You Have ({{ return_data.length }})<br />
          Unpaid Purchases</span
        >
        <br />
        <span class="success-sub-text" style="font-size: 0.9em"
          >You will be unable to use CanPay for purchases until all outstanding
          purchases are paid.</span
        >
      </div>
      <div class="one mx-auto mb-4" v-else>
        <span class="success-text" style="font-size: 1em"
          >Select Your Re-Payment Date</span
        >
      </div>
      <div class="div-container">
        <div class="inner-div" v-if="show_return_list">
          <div
            class="accordion accordian-style floatLeft"
            v-bind:class="
              index == 0 || index == return_data.length ? '' : 'ml-3'
            "
            id="returnList"
            v-for="(returns, index) in return_data"
            :key="index"
          >
            <div class="card" style="padding-left: unset; border-radius: 8px">
              <div
                class="card-header"
                id="headingTwo"
                style="
                  background-color: #ffffff;
                  border-bottom: transparent !important;
                  padding: unset;
                "
              >
                <div class="row return-padding mt-3">
                  <div class="col-12">
                    <div class="float-left">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        height="15"
                        width="15"
                        fill="#7f7f7f"
                        viewBox="0 0 48 48"
                        style="
                          enable-background: new 0 0 48 48;
                          margin-right: 10px;
                        "
                        xml:space="preserve"
                      >
                        <path
                          d="M42,4h-2V2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2H12V2c0-1.1-0.9-2-2-2S8,0.9,8,2v2H6c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h36
	c3.3,0,6-2.7,6-6V10C48,6.7,45.3,4,42,4z M44,42c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V10c0-1.1,0.9-2,2-2h2v2c0,1.1,0.9,2,2,2
	c1.1,0,2-0.9,2-2V8h24v2c0,1.1,0.9,2,2,2s2-0.9,2-2V8h2c1.1,0,2,0.9,2,2V42z"
                        />
                        <path
                          d="M38,18H10c-1.1,0-2,0.9-2,2s0.9,2,2,2h28c1.1,0,2-0.9,2-2S39.1,18,38,18z"
                        />
                      </svg>

                      <span class="return-transaction-date">{{
                        transactiontime(index)
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row return-padding mt-2 mb-3">
                  <div class="col-12">
                    <span class="return-amount float-left"
                      >${{ transactionamount(index) }}</span
                    >
                  </div>
                </div>
                <div class="return-div-gray">
                  <div class="row">
                    <div class="col-12 return-pl return-pt">
                      <span class="return-text-bold">{{
                        returns.store_name
                      }}</span>
                    </div>
                    <div class="col-12 return-pl return-pt-sm return-pb">
                      <div class="float-left">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          height="20"
                          width="15"
                          fill="#7f7f7f"
                          viewBox="0 0 90.6 100"
                          style="
                            enable-background: new 0 0 90.6 100;
                            margin-right: 10px;
                          "
                          xml:space="preserve"
                        >
                          <path
                            d="M45.3,0L0,30.1v21h90.6v-21L45.3,0z M9,42.1v-7.2l36.3-24.1l36.3,24.1v7.2L9,42.1z"
                          />
                          <rect x="0.1" y="91" width="90.4" height="9" />
                          <rect x="11.6" y="57.9" width="11.5" height="26.3" />
                          <rect x="67.5" y="57.9" width="11.5" height="26.3" />
                          <rect x="39.6" y="57.9" width="11.5" height="26.3" />
                        </svg>
                        <span class="return-text"
                          >Returned from bank account xxxx{{
                            returns.account_no
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row return-padding mt-3 mb-3">
                  <div class="col-12">
                    <span class="return-font-red float-left"
                      >Payment Returned</span
                    >
                  </div>
                </div>
                <div class="row return-padding">
                  <div class="col-12">
                    <span class="text-wrap return-text-bold">{{
                      returns.return_title
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="returns.repayment_scheduled == 1"
                  class="return-padding mt-4 mb-3"
                >
                  <div class="row">
                    <div class="col-12">
                      <span class="return-text-bold"
                        >Your transaction is scheduled for processing<br />
                        on
                        {{
                          formatDate(returns.repayment_offset_post_date)
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="row" v-if="returns.is_represented == 1">
                    <div class="col-12 text-left">
                      <span class="return-text"
                        >Initiated on
                        <span style="color: black !important"
                          ><b>{{ returnInitiatedTime(index) }}</b></span
                        >.<br />
                        This can take up to
                        <span style="color: black !important"
                          ><b>2-4 banking days</b></span
                        ><br />
                        to clear your account.</span
                      >
                    </div>
                  </div>
                  <div class="row" style="margin-top: 31px" v-else></div>
                </div>
                <div
                  v-else-if="
                    returns.consumer_approval == 1 &&
                    returns.approved_to_represent == 1 &&
                    returns.represent_block == 1
                  "
                  class="return-padding mt-4 mb-3"
                >
                  <div class="row">
                    <div class="col-12">
                      <span class="return-status-text-red"
                        >Status: Debit In Process</span
                      >
                    </div>
                  </div>
                  <div class="row" v-if="returns.is_represented == 1">
                    <div class="col-12 text-left">
                      <span class="return-text"
                        >Initiated on
                        <span style="color: black !important"
                          ><b>{{ returnInitiatedTime(index) }}</b></span
                        >.<br />
                        This can take up to
                        <span style="color: black !important"
                          ><b>2-4 banking days</b></span
                        ><br />
                        to clear your account.</span
                      >
                    </div>
                  </div>
                  <div class="row" style="margin-top: 31px" v-else></div>
                </div>
                <div v-else class="row return-padding mt-4 mb-3">
                  <div class="col-12 text-center">
                    <button
                      v-if="returns.bank_change == 1"
                      type="button"
                      class="btn-signup-splash"
                      @click="returnRepresent(index)"
                    >
                      {{ returnRepresentText }}
                    </button>
                    <button
                      v-else
                      class="btn tip-ok-btn"
                      style="margin-top: unset"
                      @click="returnRepresent(index)"
                    >
                      <span class="forgetpassword-ok-label">Re-Pay Now</span>
                    </button>
                  </div>
                  <div
                    class="col-12 text-left mt-3 mb-1"
                    v-if="
                      notThisSprint &&
                      returns.reason_code == 'R01' &&
                      (returns.represent_count == 0 ||
                        returns.represent_count == 2)
                    "
                  >
                    <a
                      href="javascript:void(0);"
                      @click="scheduleRepayment(returns.id)"
                      class="schedule-repayment-link"
                      >Schedule Re-Payment</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inner-div" v-else>
          <div class="card" style="padding-left: unset; border-radius: 8px">
            <input
              type="hidden"
              name="repaymentDate"
              id="repaymentDate"
              value=""
            />
            <div id="datepicker-schedule-repayment"></div>
            <div class="col-12 text-center mt-3 mb-3">
              <button
                class="btn tip-btn"
                style="margin-top: unset"
                @click="cancelRepaymentSchedule()"
              >
                <span class="forgetpassword-ok-label">Cancel</span>
              </button>
              <button
                class="btn tip-ok-btn"
                style="margin-top: unset"
                @click="confirmPaymentSchedule()"
              >
                <span class="forgetpassword-ok-label">Schedule Payment</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- change bank account details modal -->
    <b-modal
      ref="change-bank-modal"
      id="change-bank-modal"
      hide-footer
      v-b-modal.modal-center
      no-close-on-backdrop
      hide-header
      centered
    >
      <div class="row mt-3">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e24141"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          You currently have unpaid purchases. Your bank informed us that they
          could not complete your payment. In order to pay for your purchase and
          continue using CanPay, you must update your banking details.
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button
            @click="hideModal('change-bank-modal')"
            class="btn btn-danger btn-md center-block tip-btn"
          >
            <span class="forgetpassword-ok-label">Remind Me Later</span>
          </button>
          <button
            @click="changeBankAccount"
            class="btn btn-danger btn-md center-block tip-ok-btn"
          >
            <span class="forgetpassword-ok-label">Update Banking</span>
          </button>
        </div>
      </div>
    </b-modal>

    <!-- validation error modal -->
    <b-modal
      ref="validation-modal"
      no-close-on-backdrop
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text mt-4">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <div class="row text-center mt-4">
            <div class="col-12">
              <button
                type="button"
                class="btn-black"
                style="height: 60px"
                @click="hideModal('validation-modal')"
              >
                <span class="purchasepower-modal-ok-label">OK</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- change bank details manually -->
    <b-modal
      ref="manual-bank-update"
      no-close-on-backdrop
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="manual-bank-update"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text mt-4">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Please update your linked bank account and pay balance due.
            </label>
          </div>
          <div class="row mt-4">
            <div class="col-12 text-center">
              <div class="form-group">
                <input
                  v-model="routingNumber"
                  autocomplete="off"
                  class="form-control left-padding"
                  placeholder="Routing Number"
                  @keypress="isNumber($event)"
                  type="text"
                  inputmode="numeric"
                  maxlength="9"
                />
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 text-center">
              <div class="form-group">
                <div class="form-group">
                  <input
                    class="form-control left-padding"
                    autocomplete="off"
                    v-model="accountNumber"
                    placeholder="Account Number (0-9 , A-Z only)"
                    type="text"
                    @keypress="isAlphanumeric($event)"
                    maxlength="17"
                    minlength="5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                @click="hideModal('manual-bank-update')"
                class="btn btn-danger btn-md center-block tip-btn"
              >
                <span class="forgetpassword-ok-label">Cancel</span>
              </button>
              <button
                @click="updateBankDetailsIntoManual()"
                class="btn btn-danger btn-md center-block tip-ok-btn"
              >
                <span class="forgetpassword-ok-label">Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- warning insufficient fund -->
    <b-modal
      ref="warning-insufficient-fund"
      no-close-on-backdrop
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="warning-insufficient-fund"
      centered
    >
      <div class="color">
        <div class="row mt-4">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              class="align-self-center"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e24141"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
        </div>
        <div class="d-block text-center m-3">
          <span style="color: black; font-size: 1.5rem; font-weight: 600"
            >Warning!</span
          >
        </div>
        <div class="d-block text-center">
          <span style="color: #5b5a5a">
            Your bank is reporting insufficient balance to pay for this
            purchase. Please deposit additional funds.
            <br /><br />
            If you recently deposited funds, it can take up to 12 hours for your
            bank to report the new balance.
          </span>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <button
              type="button"
              class="btn-black"
              style="
                height: 60px;
                background: #149240 !important;
                border: none !important;
              "
              @click="repayDebit(transaction, 0)"
            >
              <span class="purchasepower-modal-ok-label"
                >Continue and Re-Pay</span
              >
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button
              type="button"
              class="btn-black"
              style="
                height: 60px;
                background: #000000 !important;
                border: none !important;
              "
              @click="changeBankAccount()"
            >
              <span class="purchasepower-modal-ok-label">Change Bank</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- represent success -->
    <b-modal
      ref="represent-success"
      no-close-on-backdrop
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="represent-success"
      centered
    >
      <div class="color">
        <div class="row mt-4">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="70"
              height="70"
              viewBox="0 0 63.9 64.4"
              style="enable-background: new 0 0 63.9 64.4"
              xml:space="preserve"
              fill="#149240"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M56.9,33.2c-0.4,0-0.9,0-1.3,0c-0.9,0-1.9,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c0.1,0,0.3,0,0.4,0c0.3,0-0.2,0-0.2,0
				c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1-0.4-0.2-0.1-0.1c0.2,0.1,0.4,0.2,0.7,0.4
				c0.2,0.2-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1c0.2,0.2-0.1-0.1-0.1-0.1
				c0,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c-0.2-0.4-0.1-0.2,0-0.1c0,0.1,0.1,0.2,0.1,0.4
				c0,0.1,0,0.1,0,0.2c0.1,0.3,0-0.2,0-0.2c0,0.3,0,0.5,0,0.8c0,0.1,0,0.1,0,0.2c0,0,0.1-0.5,0-0.2c0,0.1-0.1,0.3-0.1,0.4
				c0,0.1-0.1,0.2-0.1,0.4C58,40,58.1,40,58,40.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.2
				c0,0,0.3-0.3,0.1-0.1c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0.4-0.3,0.1-0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
				c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.2,0,0.2-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c0,0,0.5,0,0.2,0
				c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3,0-2,0c-0.9,0-1.9,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c0.1,0,0.3,0,0.4,0
				c0.3,0-0.2,0-0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1-0.4-0.2-0.1-0.1
				c0.2,0.1,0.4,0.2,0.7,0.4c0.2,0.2-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1
				c0.2,0.2-0.1-0.1-0.1-0.1c0,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c-0.2-0.4-0.1-0.2,0-0.1
				c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.1,0,0.2c0.1,0.3,0-0.2,0-0.2c0,0.3,0,0.5,0,0.8c0,0.1,0,0.1,0,0.2c0,0,0.1-0.5,0-0.2
				c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0.1-0.3,0.1-0.3,0.1-0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
				c0,0-0.1,0.1-0.1,0.2c0,0,0.3-0.3,0.1-0.1c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0.4-0.3,0.1-0.1c-0.1,0-0.1,0.1-0.2,0.1
				c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.2,0,0.2-0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1
				c0,0,0.5,0,0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3,0-2,0c-0.9,0-1.9,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
				c0.2,0,0.3,0,0.5,0c0,0-0.5-0.1-0.2,0c0.1,0,0.2,0,0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1-0.1-0.1-0.2-0.1
				c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1c-0.4-0.2-0.2-0.2-0.1-0.1c0.1,0.1,0.2,0.2,0.3,0.3
				c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.3,0.2,0.4
				c0.1,0.2-0.2-0.4-0.1-0.1c0,0.1,0,0.1,0.1,0.2c0.1,0.2,0.1,0.3,0.1,0.5c0.1,0.3,0-0.4,0-0.1c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5
				c0,0,0,0,0,0.1c0,0.2,0,0.2,0-0.1c0,0,0,0.2,0,0.2c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.4,0.2-0.4,0,0c0,0.1-0.1,0.1-0.1,0.2
				c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2,0.3-0.3,0-0.1c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.4,0.3-0.2,0.1
				c0.2-0.2-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.4,0.2-0.1,0.1c0.3-0.1-0.1,0-0.1,0
				c-0.1,0-0.1,0-0.2,0.1s-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0c0.3,0,0.3,0,0.2,0c-0.2,0-0.5,0-0.7,0c-2.3,0-4.6,0-6.8,0
				c-3.6,0-7.1,0-10.7,0c-2.6,0-5.1,0-7.7,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.2-0.1c0.4,0.1,0.1,0,0,0
				c-0.1,0-0.2,0-0.3-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.9-0.2-1.7-0.5-2.5-0.7c-0.8-0.2-1.6-0.4-2.4-0.6c-0.2,0-0.3-0.1-0.5-0.1
				c0.4,0.6,0.9,1.2,1.3,1.7c0-1,0-1.9,0-2.9c0-2.3,0-4.6,0-6.9c0-2.8,0-5.5,0-8.3c0-2.4,0-4.8,0-7.2c0-1.2,0-2.3,0-3.5
				c0,0,0,0,0-0.1c-0.6,0.6-1.2,1.2-1.8,1.8c0.8,0,1.6,0,2.4,0c0.6,0,1.2,0,1.8-0.2c1.1-0.4,2-1,2.6-2c0.3-0.4,0.5-0.8,0.7-1.2
				c0.4-0.7,0.7-1.5,1.1-2.2c1.3-2.7,2.7-5.4,4-8.1c0.4-0.9,0.9-1.8,1.3-2.7c0.1-0.2,0.2-0.4,0.3-0.6c0.5-1,0.8-2.1,0.8-3.3
				c0-1.6,0-3.1,0-4.7c0-0.7,0-1.5,0-2.2c0-0.1,0-0.3,0,0.1c0,0.2,0,0.3,0,0.2c-0.2,0.6-0.2,0.5-0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.3
				c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.2-0.3-0.3,0.4-0.1,0.2c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.5-0.4,0,0
				c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1-0.2,0.1-0.2,0.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.1
				c0.1,0,0.2,0,0.2-0.1c0.5-0.1-0.3,0,0,0c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0c-0.1,0-0.2,0-0.2,0c0.1,0,0.2,0,0.3,0.1
				c0.2,0.1,0.4,0.1,0.6,0.2c0.4,0.1-0.3-0.2,0.1,0C35.8,3.9,35.9,4,36,4c0.2,0.1,0.5,0.2,0.6,0.4c-0.2-0.3-0.2-0.1-0.1,0
				c0.1,0.1,0.1,0.1,0.2,0.2C37,4.8,37.2,5,37.4,5.3c0.3,0.4-0.2-0.3,0,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.3,0.6,0.5,0.9
				c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.5-0.1-0.2,0-0.1c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.4,0.2,0.8,0.3,1.2c0,0.2,0.1,0.4,0.1,0.5
				c0.1,0.4,0-0.4,0,0c0,0.1,0,0.2,0,0.3c0,0.4,0.1,0.9,0.1,1.3c0,0.8-0.1,1.6-0.2,2.4c-0.1,0.8-0.2,1.5-0.3,2.2
				c0,0.2-0.1,0.4-0.1,0.7c0,0.1-0.1,0.4,0,0.1c0-0.3,0,0,0,0.1c-0.1,0.4-0.1,0.9-0.2,1.3c-0.2,1.6-0.5,3.2-0.7,4.8
				c-0.1,0.7-0.2,1.3-0.3,2c-0.1,0.6-0.1,1.1,0.3,1.6c0.3,0.4,0.8,0.7,1.4,0.7c1.1,0,2.2,0,3.3,0c2.3,0,4.7,0,7,0c2.1,0,4.1,0,6.2,0
				c0.5,0,0.9,0,1.4,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0c0.4,0-0.3-0.1,0,0c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2
				c0,0,0.1,0,0.1,0.1c0,0-0.4-0.2-0.2-0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1-0.2-0.2-0.2-0.1
				c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1-0.1-0.2-0.1-0.2c0,0,0.1,0.1,0.1,0.1
				c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3-0.2-0.4,0,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.4,0.1,0.6
				c0.1,0.3,0-0.4,0-0.1c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.6c0,0,0.1-0.4,0-0.2c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3
				c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0.2-0.4,0.1-0.2c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
				c0,0.1-0.2,0.3-0.1,0.1c0.2-0.2,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.1,0.1
				c0.2-0.2,0,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.4,0.2-0.1,0.1c0.3-0.1,0,0-0.1,0
				c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.5,0.1-0.2,0c0.3,0,0,0-0.1,0C57.2,33.2,57,33.2,56.9,33.2
				c-0.9,0-1.9,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c2.3,0,4.4-1.1,5.7-3c1.3-1.7,1.6-4.1,0.8-6.1c-0.8-2.1-2.4-3.7-4.6-4.4
				c-0.8-0.2-1.6-0.3-2.4-0.3c-1.9,0-3.7,0-5.5,0c-2.5,0-5,0-7.5,0c-1.4,0-2.9-0.1-4.4,0c0,0,0,0-0.1,0c0.6,0.8,1.2,1.5,1.7,2.3
				c0.5-3.3,1.1-6.6,1.5-9.9c0.2-1.5,0.4-3.1,0.4-4.7c0-1.9-0.3-3.8-1.1-5.6c-0.7-1.5-1.7-2.8-3.1-3.8c-1.8-1.3-4.2-1.7-6.3-1
				c-1.4,0.5-2.6,1.4-3.4,2.6c-0.4,0.5-0.6,1.1-0.6,1.8c0,1.8,0,3.7,0,5.5c0,0.3,0,0.7,0,1c0,0.3,0.1,0.7,0,0.9c0-0.1,0-0.2,0-0.2
				c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.2,0-0.1
				c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0.3-0.3,0.7-0.5,1c-1.4,2.7-2.7,5.5-4.1,8.2c-0.8,1.6-1.6,3.1-2.3,4.7c-0.1,0.1-0.1,0.2-0.2,0.4
				c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.3,0.3-0.3,0,0c-0.1,0.1-0.3,0.2-0.4,0.4c0.1-0.1,0.1-0.1,0.2-0.2
				c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.4,0.2c0.1,0,0.2-0.1,0.2-0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1
				c0,0-0.1,0-0.1,0c-0.2,0-0.1,0,0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.5,0-1,0-1.5,0c-0.3,0-0.6,0-0.9,0
				c-1,0-1.8,0.8-1.8,1.8c0,1,0,1.9,0,2.9c0,2.3,0,4.6,0,6.9c0,2.8,0,5.5,0,8.3c0,2.4,0,4.8,0,7.2c0,1.2,0,2.3,0,3.5c0,0,0,0,0,0.1
				c0,0.8,0.6,1.6,1.3,1.7c1.3,0.3,2.5,0.5,3.8,0.9c0.7,0.2,1.4,0.5,2.2,0.6c1,0.2,2,0.3,3,0.3c0.2,0,0.3,0,0.5,0c2.5,0,4.9,0,7.4,0
				c3.7,0,7.3,0,11,0c2.4,0,4.8,0,7.2,0c0.3,0,0.6,0,0.9,0c1.9-0.2,3.7-1.3,4.7-3c1.8-3,0.5-7.2-2.7-8.6c-0.8-0.4-1.6-0.6-2.5-0.6
				c0,1.2,0,2.4,0,3.6c0.7,0,1.3,0,2,0c1.5,0,2.8-0.5,4-1.4c1.3-1,2-2.6,2.2-4.2c0.2-1.6-0.4-3.2-1.4-4.4c-1.1-1.4-2.9-2.2-4.7-2.2
				c0,1.2,0,2.4,0,3.6c0.7,0,1.3,0,2,0c1.5,0,2.8-0.5,4-1.4c1.3-1,2-2.6,2.2-4.2c0.2-1.6-0.4-3.2-1.4-4.4c-1.1-1.4-2.9-2.2-4.7-2.2
				c0,1.2,0,2.4,0,3.6c0.4,0,0.9,0,1.3,0c0.9,0,1.9-0.8,1.8-1.8C58.7,34,57.9,33.2,56.9,33.2z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M14.9,60.8c-3.4,0-6.7,0-10.1,0c-0.4,0-0.8,0-1.2,0c-0.3,0,0.5,0.1,0,0c-0.3-0.1,0.4,0.2,0.1,0c0,0-0.1-0.1-0.2-0.1
				c0.1,0.1,0.1,0.1,0.2,0.2c0,0-0.1-0.1-0.1-0.1c-0.2-0.2,0.2,0.4,0.1,0.1c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.1,0.1,0.2
				c0,0-0.1-0.5-0.1-0.1c0,0.3,0,0,0-0.1c0-0.8,0-1.6,0-2.4c0-3.7,0-7.4,0-11.1c0-4.2,0-8.5,0-12.7c0-1.5,0-2.9,0-4.4
				c0-0.4,0-0.8,0-1.2c0-0.2,0-0.4,0-0.5c0.1,0.3-0.1,0.4,0,0.2c0,0,0-0.1,0-0.1c0.1-0.2,0-0.1-0.1,0.2c0,0,0.1-0.2,0.1-0.2
				c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0.1-0.1,0.1-0.1c0.2-0.2-0.4,0.2-0.1,0.1c0,0,0.1-0.1,0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
				c0.1,0,0.1,0,0.2-0.1c0.3-0.1-0.5,0-0.1,0c0.1,0,0.2,0,0.3,0c0.7,0,1.4,0,2.1,0c2.1,0,4.2,0,6.4,0c0.7,0,1.4,0,2.1,0
				c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0,0c-0.3-0.1,0,0,0,0c0.3,0.1-0.4-0.2-0.1,0c0,0,0.1,0,0.1,0c0.2,0.1,0.1,0.1-0.1-0.1
				c0,0,0.2,0.2,0.2,0.2c-0.3-0.2-0.2-0.3-0.1-0.1c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.1-0.1-0.2c0,0.1,0,0.1,0.1,0.2c0,0.2,0,0.1,0-0.2
				c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2c0,3.5,0,6.9,0,10.4c0,4.3,0,8.7,0,13C14.8,55.9,14.8,58.3,14.9,60.8
				C14.8,60.7,14.8,60.8,14.9,60.8C14.8,61.2,14.9,60.4,14.9,60.8C14.8,60.7,14.8,60.8,14.9,60.8c-0.1,0.2-0.1,0.2,0-0.1
				c0,0-0.1,0.2-0.1,0.2C14.7,61,15.1,60.5,14.9,60.8C14.8,60.8,14.8,60.8,14.9,60.8C14.6,60.9,14.7,60.9,14.9,60.8
				c0-0.1-0.1,0-0.2,0.1C14.8,60.8,14.9,60.8,14.9,60.8C14.9,60.7,14.8,60.8,14.9,60.8C14.4,60.9,15.2,60.7,14.9,60.8
				c-0.9,0-1.9,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c2-0.1,3.5-1.6,3.6-3.6c0-0.2,0-0.5,0-0.7c0-1.2,0-2.4,0-3.7c0-4.2,0-8.4,0-12.5
				c0-4,0-8,0-12.1c0-1,0-2.1,0-3.1c0-0.5,0-1-0.2-1.5c-0.4-1.2-1.4-2.1-2.7-2.3c-0.3-0.1-0.6-0.1-0.9-0.1c-3.6,0-7.2,0-10.8,0
				c-0.1,0-0.3,0-0.4,0c-1.3,0.1-2.5,0.8-3,1.9C0.1,27.4,0,28,0,28.7c0,1,0,2,0,3c0,4,0,8,0,12c0,4.2,0,8.4,0,12.6
				c0,1.2,0,2.5,0,3.7c0,0.3,0,0.5,0,0.8c0,1.9,1.5,3.4,3.4,3.6c0.4,0,0.9,0,1.3,0c0.9,0,1.9,0,2.8,0c2.1,0,4.2,0,6.3,0
				c0.4,0,0.8,0,1.1,0c0.9,0,1.9-0.8,1.8-1.8C16.6,61.6,15.9,60.8,14.9,60.8z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="d-block text-center m-3">
          <span style="color: black; font-size: 1.5rem; font-weight: 600"
            >Thanks for initiating your repayment!</span
          >
        </div>
        <div class="d-block text-center">
          <span style="color: #5b5a5a">
            CanPay has initiated a debit for
            <span style="color: black !important"
              ><b>{{ debit_amount }}</b></span
            >
            from your account number
            <span style="color: black !important"
              ><b>xxxx{{ return_initiated_account }}</b></span
            >. <br /><br />
            <span style="color: black !important"
              ><b>This will take at least 2 business days,</b></span
            >
            please make sure sufficient funds remain available in your account.
          </span>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <button
              type="button"
              class="btn-black"
              style="
                height: 60px;
                background: #149240 !important;
                border: none !important;
              "
              @click="hideModal('represent-success')"
            >
              <span class="purchasepower-modal-ok-label">OK</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Cofirm transaction re-debit approve modal -->
    <b-modal
      ref="confirm-debit"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="confirm-debit"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text mt-4">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Are you sure you want to re-debit from your bank account for this
              unpaid purchase: ${{ debit_amount }}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                @click="hideModal('confirm-debit')"
                class="btn btn-danger btn-md center-block tip-btn"
              >
                <span class="forgetpassword-ok-label">No</span>
              </button>
              <button
                @click="repayDebit(transaction, balance_checking)"
                class="btn btn-danger btn-md center-block tip-ok-btn"
              >
                <span class="forgetpassword-ok-label">Yes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Confirm Bank account Modal -->
    <b-modal
      ref="confirm-bank-account"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="confirm-bank-account"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text mt-4">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Your account will be debited against XXXX{{ bank_account_number }}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                class="btn btn-danger btn-md center-block tip-btn"
                @click="changeBankAccount()"
              >
                <span class="forgetpassword-ok-label">Change Bank</span>
              </button>
              <button
                class="btn btn-danger btn-md center-block tip-ok-btn"
                @click="confirmBankAccount()"
              >
                <span class="forgetpassword-ok-label">Confirm</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Repayment schedule Modal -->
    <b-modal
      ref="confirm-repayment-schedule"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="confirm-repayment-schedule"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text mt-4">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Please confirm funds<br />
              will be available<br />
              in your account.
            </label>
            <br />
            <br />
            <span
              >CanPay will debit your account on<br />
              <b>{{ reschedule_date_text }}</b
              >. Once you agree, you<br />
              will not be able to change this date.
            </span>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                class="
                  btn btn-login-signup btn-md
                  center-block
                  tip-ok-btn
                  pad-0
                  mt-6
                "
                @click="confirmRepayment()"
              >
                <span class="forgetpassword-ok-label"
                  >Agree, funds will be available</span
                >
              </button>
              <button
                class="btn btn-login btn-md center-block tip-btn mt-3"
                @click="cancelRepaymentSchedule()"
              >
                <span class="forgetpassword-ok-label">Cancel Schedule</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <real-account-number-modal page_name="return-list" ref="RealAccountNumberModal"></real-account-number-modal>
  </div>
</div>
</template>
<script>
import api from "../api/transactiondetails.js";
import account from "../api/account.js";
import Loading from "vue-loading-overlay";
import moment from "moment";
import RealAccountNumberModal from './Payment/RealAccountNumberModal.vue';
import CanPayLoader from"./CustomLoader/CanPayLoader.vue"
export default {
  name: "ReturnTransactionDetails",
  data() {
    return {
      return_data: [],
      fullPage: true,
      isLoading: false,
      debit_amount: 0.0,
      transaction: {},
      show_repay: true,
      currentUser: {},
      error_message: "",
      routingNumber: "",
      accountNumber: "",
      show_modal: 0,
      refresh: false,
      notThisSprint: false,
      return_date: "",
      balance_checking: 1,
      return_initiated_account: "",
      show_return_list: true,
      enableDates: {},
      bank_account_number: "",
      bank_account_id: "",
      return_transaction_id: "",
      reschedule_date_text: "",
      returnRepresentText: "Change Bank and Re-Pay Now"
    };
  },
  created() {
    localStorage.removeItem("account_id");
    localStorage.removeItem("changeBankAccount");
    localStorage.removeItem("need_bank_link");
    localStorage.removeItem("can_choose_manual_direct_bank");
    localStorage.removeItem("return_transaction_id");
    localStorage.removeItem("repaymentDate");
    localStorage.removeItem("repaymentSchedule");
    var self = this;
    self.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
    self.getReturnTransactions();
    if(self.currentUser.admin_driven_bank_link == 1){
      if(self.currentUser.bank_link_type == 1){
        self.returnRepresentText = "Select Bank and Re-Pay Now";
      } else {
        self.returnRepresentText = "Re-Pay Now";
      }
    }
  },
  components: {
    Loading,
    RealAccountNumberModal,
    CanPayLoader
  },
  methods: {
    transactionamount(data) {
      return parseFloat(this.return_data[data].consumer_bank_posting_amount).toFixed(2);
    },
    transactiontime(data) {
      return moment
        .utc(this.return_data[data].created_at)
        .local()
        .format(" DD MMM, YYYY | hh:m A");
    },
    formatDate(data) {
      return moment.utc(data).local().format(" MMM DD, YYYY");
    },
    returnInitiatedTime(data) {
      return moment
        .utc(this.return_data[data].initiated_on)
        .local()
        .format(" DD MMM YYYY");
    },
    getReturnTransactions() {
      var self = this;
      self.isLoading = true;
      api
        .getReturnTransactions()
        .then((response) => {
          self.return_data = response.data;
          self.show_modal = 0;
          self.show_repay = true;
          setTimeout(() => {
            self.$refs.RealAccountNumberModal.checkForRealAccountNumber();
          }, 2000);
          
          if(self.currentUser.hasOwnProperty('account_no') == false || self.currentUser.account_no == 'XXXX'){
            self.$bvModal.show("no-primary-account-modal");
          } 
          if (response.data.length == 0) {
            self.isLoading = false;
            self.$router.push("/pay");
          }
          self.balance_checking = self.currentUser.bank_link_type;

          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
          if(self.currentUser.hasOwnProperty('account_no') == false || self.currentUser.account_no == 'XXXX'){
            self.$bvModal.show("no-primary-account-modal");
          }
          if (error.response.data.code === 598) {
            self.return_data = error.response.data.data.data;
            self.show_modal = error.response.data.data.show_modal;

            self.balance_checking = self.currentUser.bank_link_type;
            // self.$bvModal.show("change-bank-modal");
            
            
            self.show_repay = false;
          } else {
            alert(error.response.data.message);
          }
        }).finally(function() { 
          if(self.currentUser.hasOwnProperty('account_no') == false || self.currentUser.account_no == 'XXXX'){
            self.$bvModal.show("no-primary-account-modal");
          }
          
          // filter any return data has need direct bank link or not 
          let need_bank_link = self.return_data.filter(function(re_data) {
            return re_data.need_bank_link == 1;
          });
          if(need_bank_link.length > 0){
            localStorage.setItem("need_bank_link", true);
          }
          
          // If a Consumer has R03, R04 or similar retunes where bank change is mandatory, in those scenarios they can select to either Manual link or Direct link their account
          let can_choose_manual_direct_bank = self.return_data.filter(function(re_data) {
            return re_data.can_choose_manual_direct_bank == 1;
          });
          if(can_choose_manual_direct_bank.length > 0){
            localStorage.setItem("can_choose_manual_direct_bank", true);
          }
        });
    },
    returnRepresent(data) {
      this.transaction = data;
      this.debit_amount = this.return_data[data].consumer_bank_posting_amount;
      localStorage.setItem("debit_amount",this.debit_amount);     
      localStorage.setItem("balance_checking",this.balance_checking);  
      localStorage.setItem("transaction_id",this.return_data[this.transaction].id);        
      localStorage.setItem('changeBankAccount',this.return_data[data].bank_change);
      
      if(localStorage.getItem('changeBankAccount'))
      {
        localStorage.setItem('account_id',this.return_data[data].account_id)
        localStorage.setItem('account_no_original',this.return_data[data].account_no_original)
        localStorage.setItem('routing_no_original',this.return_data[data].routing_no_original)                
      }
      // Current user bank link type and mandatory bank link true then user must chagne bank through direct link
      if(this.balance_checking == 0 && localStorage.getItem("need_bank_link") != null && localStorage.getItem("can_choose_manual_direct_bank") == null){
        this.$router.push("/banklinking").catch((err) => {});
      }else{
        this.currentUser = JSON.parse(
          localStorage.getItem("consumer_login_response")
        );
        
        if(this.currentUser.bank_link_type == 0 && this.currentUser.admin_driven_bank_link == 1 && this.currentUser.account_no != 'XXXX' && this.currentUser.account_id && this.currentUser.account_id != this.return_data[data].account_id){
          this.returnRepresentTransaction();
        }else{
          this.$router.push("/returnbanklist").catch((err) => {});
        }
      }
      
    },
    // represent unpaid debit for manually linked bank account consumers
    returnRepresentTransaction() {
      var self = this;
      self.isLoading = true;
      var request = {
        id: localStorage.getItem("transaction_id"),
        balance_checking: 0,
        bank_account_id: self.currentUser.account_id,
      };

      account
        .representReturnTransaction(request)
        .then((response) => {
          self.isLoading = false;
          self.debit_amount = self.debit_amount;
          self.transaction = {};
          self.return_initiated_account = response.data;
          self.$refs["represent-success"].show();
          self.getReturnTransactions();
        })
        .catch(function (error) {
          self.isLoading = false;
          if (error.response.data.code == 598) {
            self.$refs["warning-insufficient-fund"].show();
          } else {
            self.error_message = error.response.data.message;
            self.$refs["validation-modal"].show();
          }
          self.isLoading = false;
        });
    },
    showSubmitModal(data) {
      this.transaction = data;
      this.debit_amount = this.return_data[data].consumer_bank_posting_amount;
      this.$refs["represent-debit"].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
      this.debit_amount = 0.0;
      if (this.refresh) {
        this.getReturnTransactions();
      }
    },
    changeBankAccount() {
      this.$refs["warning-insufficient-fund"].hide();
      this.$refs["confirm-bank-account"].hide();
      localStorage.setItem("redirect-return", true);
      // check if return type is R03 or R04 then show manual account change modal
      if (this.show_modal == 1) {
        this.hideModal("change-bank-modal");
        this.$refs["manual-bank-update"].show();
      } else {
        if (this.currentUser.bank_link_type == 0) {
          this.$router.push("/banklinking");
        } else {
          this.$router.push("/banklisting");
        }
      }
    },
    // represent unpaid debit for manully linked bank account consumers
    repayDebit(data, check_balance) {
      var self = this;
      self.$refs["warning-insufficient-fund"].hide();
      self.$refs["confirm-debit"].hide();
      self.isLoading = true;
      self.transaction = data;
      var request = {
        id: this.return_data[this.transaction].id,
        balance_checking: check_balance,
      };
      api
        .representTransaction(request)
        .then((response) => {
          self.debit_amount = self.return_data[self.transaction].consumer_bank_posting_amount;
          self.transaction = {};
          self.return_initiated_account = response.data;
          self.$refs["represent-success"].show();
          self.getReturnTransactions();
        })
        .catch(function (error) {
          if (error.response.data.code == 598) {
            self.$refs["warning-insufficient-fund"].show();
          } else {
            self.error_message = error.response.data.message;
            self.$refs["validation-modal"].show();
          }
          self.isLoading = false;
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    showValidationModal(msg, refresh) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
      this.refresh = refresh;
    },
    updateBankDetailsIntoManual() {
      var self = this;
      self.$refs["manual-bank-update"].hide();
      var numberRegex = /^[0-9\s]+$/;
      var alphanumberRegex = /^[0-9a-zA-Z\s]+$/;
      if (
        self.accountNumber != "" &&
        self.routingNumber != "" &&
        self.accountNumber == self.routingNumber
      ) {
        self.check = true;
        self.showValidationModal(
          "Account number and routing number can't be same. Please provide valid banking details or link directly.",
          false
        );
        return false;
      } else if (
        (self.accountNumber === "" && self.routingNumber === "") ||
        (!alphanumberRegex.test(self.accountNumber) &&
          !numberRegex.test(self.routingNumber)) ||
        ((self.accountNumber.length > 30 || self.accountNumber.length < 5) &&
          self.routingNumber.length != 9) ||
        (!alphanumberRegex.test(self.accountNumber) &&
          self.routingNumber.length != 9) ||
        (self.accountNumber.indexOf(" ") >= 0 &&
          self.routingNumber.indexOf(" ") >= 0)
      ) {
        self.showValidationModal(
          "Please provide valid routing and account number fields.",
          false
        );
        return false;
      } else if (
        self.accountNumber.length > 30 ||
        self.accountNumber.length < 5
      ) {
        self.showValidationModal(
          "Invalid account number (must be minimum 5 characters)",
          false
        );
        return false;
      } else if (self.routingNumber.length != 9) {
        self.showValidationModal(
          "Invalid routing number (must be 9 digits)",
          false
        );
        return false;
      } else if (
        !alphanumberRegex.test(self.accountNumber) ||
        self.accountNumber.indexOf(" ") >= 0
      ) {
        self.showValidationModal("Account Number(Alphanumeric Only)", false);
        return false;
      } else if (
        !numberRegex.test(self.routingNumber) ||
        self.routingNumber.indexOf(" ") >= 0
      ) {
        self.showValidationModal("Routing number (numbers only)", false);
        return false;
      } else if (self.checkAccountNumberContainNumber(self.accountNumber)) {
        self.showValidationModal(
          "Account Number must have minimum 4 numbers",
          false
        );
        return false;
      }
      self.isLoading = true;
      var request = {
        accountNumber: self.accountNumber,
        routingNumber: self.routingNumber,
        return: 1,
      };
      account
        .storeManualBankDetails(request)
        .then((response) => {
          self.currentUser.bank_link_type = 0;
          localStorage.setItem(
            "consumer_login_response",
            JSON.stringify(self.currentUser)
          );
          self.showValidationModal(
            "You have successfully updated your bank account. Please approve and pay your balance due.",
            true
          );
          self.balance_checking = self.currentUser.bank_link_type;
          self.getReturnTransactions();
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message, false);
          self.isLoading = false;
          self.accountNumber = "";
          self.routingNumber = "";
        });
    },
    isAlphanumeric: function (evt) {
      const regex = /[0-9a-zA-Z]/g;
      evt = evt ? evt : window.event;
      var inputKey = String(evt.key);
      if (!inputKey.match(regex)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkAccountNumberContainNumber(accountNumber) {
      let checkAccountNumber;
      checkAccountNumber = accountNumber.split("");
      let count;
      count = 0;
      checkAccountNumber.forEach(checkValue);

      function checkValue(value, index, array) {
        if (!isNaN(value)) {
          count++;
        }
      }
      var isFourDigit = count >= 4 ? false : true;
      return isFourDigit;
    },
    scheduleRepayment(id) {
      var self = this;
      if(this.balance_checking == 0 && localStorage.getItem("need_bank_link") != null){
        this.$router.push("/banklinking").catch((err) => {});
      }else{
        self.return_transaction_id = id;
        self.show_return_list = false;
        //Function call to enable and disable the dates in Datepicker
        self.getRepaymentEnabledDates();
      }
    },
    getRepaymentEnabledDates() {
      api
        .getRepaymentEnabledDates()
        .then((response) => {
          var enableDates = response;

          var firstRepaymentDate = enableDates[0].date;
          var default_date =
            firstRepaymentDate.split("-")[1] +
            "-" +
            firstRepaymentDate.split("-")[0] +
            "-" +
            firstRepaymentDate.split("-")[2];
          var sortDatesArry = [];
          var enableDatesArray = [];

          for (var i = 0; i < enableDates.length; i++) {
            var dt = enableDates[i].date.replace("-", "/").replace("-", "/");
            var dd, mm, yyy;
            if (
              parseInt(dt.split("/")[0]) <= 9 ||
              parseInt(dt.split("/")[1]) <= 9
            ) {
              dd = parseInt(dt.split("/")[0]);
              mm = parseInt(dt.split("/")[1]);
              yyy = dt.split("/")[2];
              enableDatesArray.push(dd + "/" + mm + "/" + yyy);
              sortDatesArry.push(
                new Date(yyy + "/" + dt.split("/")[1] + "/" + dt.split("/")[0])
              );
            } else {
              enableDatesArray.push(dt);
              sortDatesArry.push(
                new Date(
                  dt.split("/")[2] +
                    "/" +
                    dt.split("/")[1] +
                    "/" +
                    dt.split("/")[0] +
                    "/"
                )
              );
            }
          }

          var maxDt = new Date(Math.max.apply(null, sortDatesArry));
          var minDt = new Date(Math.min.apply(null, sortDatesArry));

          $("#datepicker-schedule-repayment")
            .datepicker({
              format: "mm-dd-yyyy",
              startDate: minDt,
              endDate: maxDt,
              beforeShowDay: function (date) {
                var dt_ddmmyyyy =
                  date.getDate() +
                  "/" +
                  (date.getMonth() + 1) +
                  "/" +
                  date.getFullYear();
                return enableDatesArray.indexOf(dt_ddmmyyyy) != -1;
              },
            })
            .on("changeDate", function () {
              var repaymentDate = $(
                "#datepicker-schedule-repayment"
              ).datepicker("getFormattedDate");
              $("#repaymentDate").val(repaymentDate);
            });
          $("#datepicker-schedule-repayment").datepicker(
            "setDate",
            default_date
          );
        })
        .catch(function (error) {});
    },
    cancelRepaymentSchedule() {
      var self = this;
      self.return_transaction_id = "";
      self.show_return_list = true;
      self.$refs["confirm-repayment-schedule"].hide();
    },
    confirmPaymentSchedule() {
      var self = this;

      if ($("#repaymentDate").val() == "") {
        self.error_message =
          "Please select the Repayment schedule date in order to schedule payment.";
        self.$refs["validation-modal"].show();
      } else {
        localStorage.setItem("return_transaction_id", self.return_transaction_id);
        localStorage.setItem("repaymentDate", $("#repaymentDate").val());
        localStorage.setItem("repaymentSchedule", true);
         localStorage.setItem("balance_checking", self.balance_checking);
        self.$router.push("/returnbanklist");
      }
    },
    confirmBankAccount() {
      var self = this;
      self.$refs["confirm-bank-account"].hide();
      self.$refs["confirm-repayment-schedule"].show();
      var repaymentDate = $("#repaymentDate").val();
      self.reschedule_date_text = self.formatDate(
        parseInt(repaymentDate.split("-")[2]) +
          "-" +
          parseInt(repaymentDate.split("-")[0]) +
          "-" +
          parseInt(repaymentDate.split("-")[1])
      );
    },
    confirmRepayment() {
      var self = this;

      var request = {
        id: self.return_transaction_id,
        account_id: self.bank_account_id,
        repayment_offset_post_date: $("#repaymentDate").val(),
      };
      api
        .addReturnTransactionPaymentSchedule(request)
        .then((response) => {
          if (response.code == 200) {
            self.return_transaction_id = "";
            self.show_return_list = true;
            self.$refs["confirm-repayment-schedule"].hide();
            self.getReturnTransactions();
          }
        })
        .catch(function (error) {});
    },
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
      element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>
<style lang="scss">
#change-bank-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
}
#confirm-debit___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#represent-debit___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#manual-bank-update___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#warning-insufficient-fund___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#represent-success___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#confirm-debit___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#confirm-bank-account___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#confirm-repayment-schedule___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.datepicker-inline {
  width: auto;
}
.table-condensed {
  margin: 0 auto !important;
}
.datepicker td,
.datepicker th {
  font-weight: bold;
}
</style>